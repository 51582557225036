
.live-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-scrollbar {
        margin: 20px;
        height: calc(100% - 40px);
    }

    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
        }
    }

    .live-breadcrumb {
        ::v-deep.el-breadcrumb__item {

            &:last-child .el-breadcrumb__inner {
                color: #ABABBB;
            }

            .el-breadcrumb__inner {
                color: #5C5C6F;
            }

            .el-breadcrumb__separator {
                color: #5C5C6F;
            }
        }
    }

    .top-title {
        margin-top: 18px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
    }

    .data-box {
        margin-top: 20px;
        background: #0C0E3F;
        box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
        border-radius: 20px;
        padding: 20px;

        .data-title {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
        }

        .data-content {
            height: 350px;
            width: 100%;
            margin-top: 30px;
        }
    }
}
